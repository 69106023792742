/*=========================================================================================
  File Name: orders.js
  Description: actions requests to orders.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'orders';

const actions = {
  list: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'list', {}, payload);

    commit('set', ['masterdata', 'orders', data.data['orders']], {
      root: true
    });
    commit(
      'set',
      ['masterdata', 'ordersStatuses', data.data['orders_statuses']],
      {
        root: true
      }
    );
    commit('set', ['masterdata', 'users', data.data['users']], { root: true });
    commit('set', ['masterdata', 'firms', data.data['firms']], { root: true });
    commit('set', ['masterdata', 'firmCodes', data.data['firm_codes']], {
      root: true
    });

    return data;
  },

  new: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'new');

    commit('set', ['masterdata', 'firms', data.data['firms']], { root: true });
    commit('set', ['masterdata', 'rangetypes', data.data['rangetypes']], {
      root: true
    });
    commit('set', ['masterdata', 'annualprices', data.data['annualprices']], {
      root: true
    });
    commit('set', ['masterdata', 'ordersTypes', data.data['orders_types']], {
      root: true
    });
    commit('set', ['masterdata', 'ordersTiming', data.data['orders_timing']], {
      root: true
    });
    commit(
      'set',
      ['masterdata', 'ordersStatuses', data.data['orders_statuses']],
      {
        root: true
      }
    );
    commit('set', ['masterdata', 'workcenters', data.data['workcenters']], {
      root: true
    });

    return data;
  },

  detail: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'detail', payload);

    commit('set', ['masterdata', 'firms', data.data['firms']], { root: true });
    commit('set', ['masterdata', 'rangetypes', data.data['rangetypes']], {
      root: true
    });
    commit('set', ['masterdata', 'annualprices', data.data['annualprices']], {
      root: true
    });
    commit('set', ['masterdata', 'ordersTypes', data.data['orders_types']], {
      root: true
    });
    commit('set', ['masterdata', 'ordersTiming', data.data['orders_timing']], {
      root: true
    });
    commit(
      'set',
      ['masterdata', 'ordersStatuses', data.data['orders_statuses']],
      {
        root: true
      }
    );

    commit('set', ['masterdata', 'indexes', data.data['indexes']], {
      root: true
    });
    commit('set', ['masterdata', 'jobtypes', data.data['jobtypes']], {
      root: true
    });
    commit('set', ['masterdata', 'languages', data.data['languages']], {
      root: true
    });
    commit('set', ['masterdata', 'usersAccount', data.data['users_account']], {
      root: true
    });

    return data;
  },

  'orders-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'orders-save', payload);

    return data;
  },

  'orders-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'orders-remove', payload);

    return data;
  },

  'orders2-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'orders2-save', payload);

    return data;
  },

  'orders2-update': async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'orders2-update', {
      orders2: payload
    });

    return Vue.$auth.searchInStatusList(data.status_list, 'ok');
  },

  'orders2-pn': async (context, id) => {
    const data = await Vue.$api.get(moduleName, 'orders2-pn', { id: id });

    return data['order2_pn'];
  },

  'orders2-duplicate': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'orders2-duplicate', payload);

    return data.saved;
  },

  'orders2-group-save': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'orders2-group-save', payload);

    return data.saved;
  },

  'orders2-group-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'orders2-group-remove',
      payload
    );

    return data.success;
  },

  'orders2field-save': async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'orders2field-save', payload);

    return Vue.$auth.searchInStatusList(data.status_list, 'ok');
  },

  'orders2-field-history-remove': async (context, payload) => {
    await Vue.$api.post(moduleName, 'orders2-field-history-remove', payload);
  },

  'prefill-form': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'prefill-form', payload);

    return data;
  },

  timing: async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'timing', payload);

    commit('set', ['masterdata', 'responsibles', data['responsibles']], {
      root: true
    });
    commit('set', ['masterdata', 'jobtypes', data['jobtypes']], {
      root: true
    });

    return data;
  },

  'timing-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'timing-save', payload);

    return data.saved;
  },

  'timing-items-sorting': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'timing-items-sorting',
      payload
    );

    return data;
  },

  'timing-to-template': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'timing-to-template', payload);

    return data;
  },

  'timing-remove': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'timing-remove', payload);

    return data;
  },

  'timing-notification-hide': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'timing-notification-hide',
      payload
    );

    return data;
  },

  tracker: async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'tracker', payload);

    commit('set', ['masterdata', 'ordersTiming', data['orders_timing']], {
      root: true
    });

    return data;
  },

  'tracker-simple': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'tracker-simple', payload);

    return data;
  },

  'bookmark-data': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'bookmark-data', payload);

    return data;
  },

  'tracker-cell-tasks': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'tracker-cell-tasks', payload);

    return data;
  },

  'tracker-cell-usersheet': async (context, payload) => {
    const data = await Vue.$api.get(
      moduleName,
      'tracker-cell-usersheet',
      payload
    );

    return data;
  },

  'tracker-cell-files': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'tracker-cell-files', payload);

    return data;
  },

  'tracker-cell-values-history': async (context, payload) => {
    const data = await Vue.$api.get(
      moduleName,
      'tracker-cell-values-history',
      payload
    );

    return data;
  },

  economy: async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'economy', payload);

    commit('set', ['masterdata', 'estimates', data['estimates']], {
      root: true
    });

    return data;
  },

  'estimatelink-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'estimatelink-save',
      payload
    );

    return data.saved;
  },

  'estimatelink-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'estimatelink-remove',
      payload
    );

    return data.success;
  },

  'estimate-to-invoice-save': async (context, payload) => {
    const data = await Vue.$api.postFiles(
      moduleName,
      'estimate-to-invoice-save',
      payload.files,
      payload.data
    );

    return data;
  },

  'invoices-multiple-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'invoices-multiple-save',
      payload
    );

    return data.saved;
  },

  'invoices-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'invoices-save', payload);

    return data.saved;
  },

  'invoices-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'invoices-remove', payload);

    return data.success;
  },

  usersheet: async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'usersheet', payload);

    commit('set', ['masterdata', 'users', data['users']], { root: true });

    return data;
  },

  'order-notes': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'order-notes', payload);

    return data;
  },

  'order-notes-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'order-notes-save', payload);

    return data.saved;
  },

  'order-notes-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'order-notes-remove',
      payload
    );

    return data;
  },

  'orders2-field-note-save': async ({ commit }, payload) => {
    const data = await Vue.$api.post(
      moduleName,
      'orders2-field-note-save',
      payload
    );

    return data.saved;
  },

  'orders2-field-note-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'orders2-field-note-remove',
      payload
    );

    return data.success;
  },

  'orders2-form': async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'orders2-form', payload);

    return data;
  },

  'product-card': async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'product-card', payload);

    commit('set', ['masterdata', 'workgroups', data['workgroups']], {
      root: true
    });

    return data;
  },

  'get-usersheet-files': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'get-usersheet-files', payload);

    return data;
  },

  'hide-rangetype2': async ({ commit }, payload) => {
    const data = await Vue.$api.update(moduleName, 'hide-rangetype2', payload);

    if (data.id > 0) {
      commit('UPDATE_HIDDEN_RANGETYPES2', payload, { root: true });

      return true;
    }
  },

  'unhide-rangetype2': async ({ commit }, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'unhide-rangetype2',
      payload
    );

    if (data.id > 0) {
      commit('UNHIDE_RANGETYPE2', payload, { root: true });

      return true;
    }
  },

  'userorders-list': async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'userorders-list', payload);

    commit('set', ['masterdata', 'orders', data['orders']], {
      root: true
    });

    return data;
  },

  'orders2-to-estimate-modal': async ({ commit }, payload) => {
    const data = await Vue.$api.get(
      moduleName,
      'orders2-to-estimate-modal',
      payload
    );

    commit('set', ['masterdata', 'currencies', data['currencies']], {
      root: true
    });

    return data;
  },

  'create-estimate-from-orders2': async (context, payload) => {
    const data = await Vue.$api.post(
      moduleName,
      'create-estimate-from-orders2',
      payload
    );

    return data;
  },

  'get-sku-group-name': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'get-sku-group-name', payload);

    return data;
  }
};

export default {
  namespaced: true,
  actions
};
